import React, { useState, useEffect } from "react";
import "./buscaAgentSignup.css";
import {
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  formatPhone,
} from "../../../utils/helpers";
import { addFile, addLender, createUser, postNotice } from "../../../utils/api";
import PlacesAutocomplete from "../../PlacesAutocomplete";

const BuscaLenderSignupForm = () => {
  const [isClosed, setIsClosed] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [languages, setLanguages] = useState("");
  const [primaryLanguage, setPrimaryLanguage] = useState("Spanish");
  const [areas, setAreas] = useState([]);
  const [license, setLicense] = useState("");
  const [brokerage, setBrokerage] = useState("");
  const [brokerageNumber, setBrokerageNumber] = useState("");
  const [description, setDescription] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setisVisible] = useState(false);
  const [isBothWebsites, setBothSites] = useState(false);
  const [formattedAddress, setFormattedAddress] = React.useState("");

  const [cityAlert, setCityAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAreasError, setShowAreasError] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (areas[0]) {
      const userData = {
        name: `${firstName} ${lastName}`,
        email: email,
        password: password,
        user_type: "Lender",
      };

      const formData = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        email: email,
        languages_spoken: languages,
        primary_language: primaryLanguage,
        areas_served: areas.join("; "),
        state_license_numbers: license,
        profile_description: description,
        company: brokerage,
        company_number: brokerageNumber,
        isDeleted: true,
      };

      // Fetch 1: Create the Agent
      createUser(userData)
        .then((res) => res.json())
        .then((data) => {
          formData.user_id = data.id;

          if (profileImage !== "") {
            // Fetch 2 Upload profile photo
            addFile(profileImage)
              .then((res) => res.json())
              .then((data) => {
                formData.image = data.fileName;

                // Fetch 3: Create the Agent
                createAgent(formData);
              })
              .catch((err) => {
                console.log(err);
                setShowError(true);
              });
          } else {
            createAgent(formData);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });

      async function createAgent(formData) {
        const response = await addLender(formData);

        if (!response.ok) {
          const errorMessage = await response.json();
          const message = errorMessage.errors[0].message;

          // Handle Unique Email Validation Error
          if (message === "email must be unique") {
            console.log("Email Should be Unique");
            setEmailAlert(true);
          } else {
            console.log(message);
            setShowError(true);
          }
        } else {
          const data = await response.json();
          const agent = data.newLender;

          const noticeData = {
            content: `${firstName} ${lastName} created an Lender Account`,
            actions: "Approve Lender",
            actions_content: agent.id,
            website: "Busca",
            user_id: agent.user_id,
            agent_id: agent.id,
          };

          // Fetch 4: Alert the Super User
          postNotice(noticeData)
            .then((res) => res.json())
            .then((data) => {
              setIsClosed(true);
              window.parent.postMessage("Success", "*");
            })
            .catch((err) => {
              console.log(err);
              setShowError(true);
            });
        }
      }
    } else {
      setShowAreasError(true);
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    console.log(formData);
    console.log(...formData);
    setProfileImage(formData);
  };

  // Check if User can submit
  useEffect(() => {
    if (description.length > 1500) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [description]);

  return (
    <div
      className={
        isClosed === true
          ? "contact-form-container home-loans busca hidden"
          : "contact-form-container home-loans busca"
      }
    >
      <div className="loans-main-wrapper">
        <div className="loans-form-wrapper">
          {/* <!-- Form --> */}
          <form
            onSubmit={handleFormSubmit}
            autoComplete="off"
            className="agent-signup-form"
          >
            <div className="loans-form-title agent-signup">
              <h2 className="">
                ¡Estamos emocionados de comenzar a trabajar con usted!
              </h2>
              <p>
                Para configurar su perfil de prestamista, complete la
                información a continuación.
              </p>
            </div>
            <hr className="loans-form-hr" />
            <div className="loans-input-flex">
              <div>
                <label htmlFor="firstname" className="loans-form-label">
                  Nombre *
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="loans-form-input"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="lastname" className="loans-form-label">
                  {" "}
                  Apellido *
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="loans-form-input"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="loans-input-flex">
              <div>
                <label htmlFor="email" className="loans-form-label">
                  {" "}
                  Correo Electrónico *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="loans-form-input"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone" className="loans-form-label">
                  {" "}
                  Número de teléfono *
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="loans-form-input"
                  required
                  value={phoneNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setPhoneNumber(phone);
                  }}
                />
              </div>
            </div>

            <div className="loans-input-flex">
              <div>
                <label htmlFor="languages" className="loans-form-label">
                  {" "}
                  Idiomas hablados *
                </label>
                <input
                  type="languages"
                  name="languages"
                  id="languages"
                  className="loans-form-input"
                  required
                  value={languages}
                  onChange={(e) => setLanguages(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="primaryLanguage" className="loans-form-label">
                  {" "}
                  Sitio web *
                </label>
                <select
                  className="agent-form-select"
                  id="primaryLanguage"
                  name="primaryLanguage"
                  value={primaryLanguage}
                  onChange={(e) => {
                    if (e.target.value === "Both") {
                      setBothSites(true);
                    } else {
                      setBothSites(false);
                    }
                    setPrimaryLanguage(e.target.value);
                  }}
                  required
                >
                  <option value={"English"}>Find A Home</option>
                  <option value={"Spanish"}>Busca Tu Casa</option>
                  <option value={"Both"}>Find A Home & Busca Tu Casa</option>
                </select>
              </div>
            </div>

            <div>
              <p className="areas-text">Áreas de servicio: * </p>
              {areas.map((area) => (
                <div key={area} id={area} className="area-btn">
                  {area}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(area);
                      // Remove from State
                      const updatedArr = removeFromArr(areas, area);
                      setAreas(updatedArr);
                      // Hide
                      document.getElementById(`${area}`).style.display = "none";
                    }}
                  >
                    X
                  </span>
                </div>
              ))}
              {showAreasError ? (
                <p className="error-message">Please enter an area to submit</p>
              ) : (
                <></>
              )}

              <div className="loans-input-flex">
                <div>
                  <label htmlFor="city" className="loans-form-label">
                    Ciudad, Estado
                  </label>
                  <PlacesAutocomplete
                    setFormattedAddress={setFormattedAddress}
                    formattedAddress={formattedAddress}
                  />
                </div>
                <div>
                  <button
                    className="areas-btn"
                    onClick={(e) => {
                      e.preventDefault();

                      console.log(formattedAddress);

                      // Check for Content
                      const cityTrimmed = formattedAddress.trim();

                      if (cityTrimmed.length !== 0) {
                        // Check for Duplicates
                        const hasDuplicates = arrayContains(
                          areas,
                          formattedAddress
                        );
                        if (hasDuplicates === false) {
                          setAreas([...areas, formattedAddress]);
                        }
                      }
                    }}
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>

            <div className="loans-mb-3">
              <label htmlFor="license" className="loans-form-label">
                Números de licencia estatal *
              </label>
              <input
                type="text"
                name="license"
                id="license"
                className="loans-form-input"
                required
                value={license}
                onChange={(e) => setLicense(e.target.value)}
              />
            </div>

            <div className="loans-input-flex">
              <div className="loans-mb-3">
                <label htmlFor="brokerage" className="loans-form-label">
                  Nombre de Compañía. *
                </label>
                <input
                  type="text"
                  name="brokerage"
                  id="brokerage"
                  required
                  className="loans-form-input"
                  value={brokerage}
                  onChange={(e) => setBrokerage(e.target.value)}
                />
              </div>
              <div className="loans-mb-3">
                <label htmlFor="brokerage-number" className="loans-form-label">
                  Número de Teléfono de Compañía. *
                </label>
                <input
                  type="text"
                  name="brokerage-number"
                  id="brokerage-number"
                  className="loans-form-input"
                  required
                  value={brokerageNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setBrokerageNumber(phone);
                  }}
                />
              </div>
            </div>

            <div className="loans-mb-3">
              <label htmlFor="description" className="loans-form-label">
                Descripción del perfil del prestamista *
              </label>
              {isBothWebsites ? (
                <p style={{ color: "#536387" }}>
                  Si desea que su perfil se muestre en Busca Tu Casa y Find A
                  Home, asegúrese de proporcionar una versión en inglés y en
                  español de la descripción de su perfil a continuación.
                </p>
              ) : (
                <></>
              )}
              <textarea
                type="text"
                name="description"
                id="description"
                required
                className="loans-form-input agent-signup-textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <p
                className={
                  canSubmit === false
                    ? "character-count"
                    : "character-count error"
                }
              >
                {description.length} / 1500{" "}
              </p>
            </div>
            <div className="loans-mb-3 agent-signup-photo-upload">
              <label htmlFor="photo" className="loans-form-label">
                Subir foto de perfil del prestamista *
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleImageChange}
                required
                accept="image/png, image/jpeg"
              />
            </div>
            <div>
              <label htmlFor="password" className="loans-form-label">
                Contraseña *
              </label>
              <input
                type={isVisible === false ? "password" : "text"}
                name="password"
                id="password"
                className="loans-form-input"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="password-span"
                style={{ color: "#343459" }}
                onClick={() => {
                  setisVisible(!isVisible);
                }}
              >
                {isVisible === false ? "Ver" : "Esconder"}
              </span>
            </div>

            {showError ? (
              <div className="message-container">
                <p className="error-message">
                  Algo salió mal con tu solicitud, por favor inténtalo de nuevo
                  más tarde.
                </p>
              </div>
            ) : (
              <></>
            )}
            {emailAlert === true ? (
              <div className="message-container">
                <p className="error-message">
                  Dirección de correo electrónico ya está en uso.
                </p>
              </div>
            ) : (
              <></>
            )}
            <button
              className={
                canSubmit === false ? "loans-btn" : "loans-btn disabled"
              }
              type="submit"
            >
              Enviar mensaje
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BuscaLenderSignupForm;
